import "./App.css";
import React from "react";
import { words } from "./database.js";

function Game() {
    const [randomWord, setRandomWord] = React.useState("");
    const [randomCase, setRandomCase] = React.useState(0);
    const [answer, setAnswer] = React.useState("");
    const [score, setScore] = React.useState(0);
    const [correctAnswer, setCorrectAnswer] = React.useState("");

    React.useEffect(() => {
        getRandomWord();
    }, []);

    const getRandomWord = () => {
        const randomWord = words[Math.floor(Math.random() * words.length)];
        const randomCase = Math.floor(Math.random() * 3);
        setRandomWord(randomWord);
        setRandomCase(randomCase);
    };

    const checkAnswer = () => {
        if (
            randomCase === 0 &&
            answer.toLocaleLowerCase() === randomWord.sana.toLocaleLowerCase()
        ) {
            setAnswer("");
            getRandomWord();
            setScore(score + 1);
        } else if (
            randomCase === 1 &&
            answer.toLocaleLowerCase() === randomWord.word.toLocaleLowerCase()
        ) {
            setAnswer("");
            getRandomWord();
            setScore(score + 1);
        } else if (
            randomCase === 2 &&
            answer.toLocaleLowerCase() === randomWord.word.toLocaleLowerCase()
        ) {
            setAnswer("");
            getRandomWord();
            setScore(score + 1);
        } else {
            setAnswer("");
            setScore(score - 1);
            if (score <= 0) {
                alert("Game Over!");
                setScore(0);
            }
            // show correct answer 10 seconds
            if (randomCase === 0) {
                setCorrectAnswer(randomWord.sana);
            } else if (randomCase === 1) {
                setCorrectAnswer(randomWord.word);
            } else if (randomCase === 2) {
                setCorrectAnswer(randomWord.word);
            }
            setTimeout(() => {
                setCorrectAnswer("");
            }, 1000);
        }
    };

    const handleAnswer = (event) => {
        setAnswer(event.target.value);
    };

    return (
        <div
            className="App"
            style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
            }}
        >
            <div className="game">
                <h1>Vocabulary Game</h1>
                <h2>Score: {score}</h2>
                <div
                    style={{
                        borderBottomStyle: "solid",
                        borderColor: "blue",
                        margin: "10px",
                        padding: "0px",
                        width: "500px",
                    }}
                >
                    {randomCase === 0 && <h2>Suomennos?</h2>}
                    {randomCase === 1 && <h2>Englanniksi?</h2>}
                    {randomCase === 2 && <h2>Sana englanniksi?</h2>}
                </div>
                <div
                    style={{
                        borderStyle: "solid",
                        borderColor: "yellow",
                        width: "500px",
                    }}
                >
                    {randomCase === 0 && <h3>{randomWord.word}</h3>}
                    {randomCase === 1 && <h3>{randomWord.sana}</h3>}
                    {randomCase === 2 && <h3>{randomWord.definition}</h3>}
                </div>
                <h3
                    style={{
                        color: "lightgreen",
                        borderStyle: "solid",
                        borderColor: "red",
                        width: "500px",
                    }}
                >
                    {correctAnswer}
                </h3>
                <input value={answer} onChange={handleAnswer} type="text" />
                <button onClick={checkAnswer}>Submit</button>
            </div>
        </div>
    );
}

export default Game;
