// eslint-disable-next-line no-unused-expressions
const words = [
    {
        word: "abstraction",
        sana: "abstraktio",
        definition: "A simplified representation of something more complex.",
    },
    {
        word: "accessibility",
        sana: "saavutettavuus",
        definition:
            "The design of products, devices, services, or environments taking into consideration the ability for all users to access, including people who experience disabilities or those who are limited by older or slower technology.",
    },
    {
        word: "algorithm",
        sana: "algoritmi",
        definition: "A list of steps to finish a task.",
    },
    {
        word: "binary",
        sana: "binaari",
        definition: "A way of representing information using only two options.",
    },
    {
        word: "byte",
        sana: "tavu",
        definition:
            "The most common fundamental unit of digital data eg. Kilobyte, Megabyte, etc. A single byte is 8 bits-worth of data.",
    },
    {
        word: "bit",
        sana: "bitti",
        definition:
            "A contraction of 'Binary Digit'. A bit is the single unit of information in a computer, typically represented as a 0 or 1.",
    },
    {
        word: "block-based programming language",
        sana: "lohkopohjainen ohjelmointikieli",
        definition:
            "Any programming language that lets users create programs by manipulating “blocks” or graphical programing elements, rather than writing code using text.",
    },
    {
        word: "Blockly",
        sana: "Blockly",
        definition:
            "The visual programming language used in Code.org's online learning system for K-5 students.",
    },
    {
        word: "bug",
        sana: "bugi",
        definition:
            "An error in a program that prevents the program from running as expected.",
    },
    {
        word: "code",
        sana: "koodi",
        definition:
            "The language that programmers create and use to tell a computer what to do.",
    },
    {
        word: "command",
        sana: "komento",
        definition:
            "An instruction for the computer. Many commands put together make up algorithms and computer programs.",
    },
    {
        word: "computer science",
        sana: "tietojenkäsittelytiede",
        definition: "Using the power of computers to solve problems.",
    },
    {
        word: "conditionals",
        sana: "ehtolause",
        definition: "Statements that only run under certain conditions.",
    },
    {
        word: "call (a variable)",
        sana: "kutsu (muuttujaa)",
        definition: "Use a variable in a program.",
    },
    {
        word: "call (a function)",
        sana: "kutsu (funktiota)",
        definition:
            "This is the piece of code that you add to a program to indicate that the program should run the code inside a function at a certain time.",
    },
    {
        word: "click",
        sana: "klikkaus",
        definition: "Press the mouse button.",
    },
    {
        word: "computational thinking",
        sana: "laskennallinen ajattelu",
        definition:
            "Modifying a problem in such a way that it can be modeled or solved using a computer.",
    },
    {
        word: "computer",
        sana: "tietokone",
        definition:
            "A machine that can be programmed to do a set of instructions.",
    },
    {
        word: "data",
        sana: "data",
        definition:
            "Information. Often, quantities, characters, or symbols that are the inputs and outputs of computer programs.",
    },
    {
        word: "debugging",
        sana: "debuggaus",
        definition: "Finding and fixing problems in an algorithm or program.",
    },
    {
        word: "decompose",
        sana: "hajauttaa",
        definition: "Break a problem down into smaller pieces.",
    },
    {
        word: "define (a function)",
        sana: "määrittää (funktio)",
        definition:
            "Figure out the details of the problems that you are trying to solve.",
    },
    {
        word: "digital citizen",
        sana: "digitaalinen kansalainen",
        definition:
            "Someone who acts safely, responsibly, and respectfully online.",
    },
    {
        word: "digital footprint",
        sana: "digitaalinen jalanjälki",
        definition: "The information about someone on the Internet.",
    },
    {
        word: "DNS (domain name service)",
        sana: "DNS (domain name service)",
        definition: "The service that translates",
    },
    {
        word: "DSL/cable",
        sana: "DSL/kupari",
        definition:
            "A method of sending information using telephone or television cables.",
    },
    {
        word: "double-click",
        sana: "kaksoisklikkaus",
        definition: "Press the mouse button very quickly two times.",
    },
    {
        word: "drag",
        sana: "vedä",
        definition:
            "Click your mouse button and hold as you move the mouse pointer to a new location.",
    },
    {
        word: "drop",
        sana: "pudota",
        definition:
            "Release your mouse button to 'let go' of an item that you are dragging.",
    },
    {
        word: "event",
        sana: "tapahtuma",
        definition: "An action that causes something to happen.",
    },
    {
        word: "event handler",
        sana: "tapahtumankäsittelijä",
        definition:
            "A monitor for a specific event or action on a computer. When you write code for an event handler, it will be executed every time that event or action occurs. Many event-handlers respond to human actions such as mouse clicks.",
    },
    {
        word: "F.A.I.L",
        sana: "F.A.I.L",
        definition: "First Attempt In Learning",
    },
    {
        word: "fiber optic cable",
        sana: "valonjohtokupari",
        definition: "A connection that uses light to transmit information.",
    },
    {
        word: "for loop",
        sana: "for-silmukka",
        definition:
            "A loop with a predetermined beginning, end, and increment (step interval).",
    },
    {
        word: "frustrated",
        sana: "suuttunut",
        definition:
            "Feeling annoyed or angry because something is not the way you want it.",
    },
    {
        word: "function",
        sana: "funktio",
        definition:
            "A piece of code that you can easily call over and over again.",
    },
    {
        word: "function call",
        sana: "funktion kutsu",
        definition:
            "The piece of code that you add to a program to indicate that the program should run the code inside a function at a certain time.",
    },
    {
        word: "function definition",
        sana: "funktion määrittely",
        definition:
            "The code inside a function that instructs the program on what to do when the function is called",
    },
    {
        word: "function parameter",
        sana: "funktion parametri",
        definition: "A variable that is used in a function definition.",
    },
    {
        word: "function return",
        sana: "funktion palautus",
        definition:
            "The value that a function returns to the program when it is called.",
    },
    {
        word: "function return type",
        sana: "funktion palautustyyppi",
        definition:
            "The type of value that a function returns to the program when it is called.",
    },
    {
        word: "function signature",
        sana: "funktion allekirjoitus",
        definition:
            "The name of a function, the types of its parameters, and the type of value it returns.",
    },
    {
        word: "function value",
        sana: "funktion arvo",
        definition:
            "The value that a function returns to the program when it is called.",
    },
    {
        word: "if-statement",
        sana: "jos",
        definition:
            "The common programming structure that implements conditional statements.",
    },
    {
        word: "input",
        sana: "syöte",
        definition: "A way to give information to a computer.",
    },
    {
        word: "Internet",
        sana: "Internet",
        definition:
            "A group of computers and servers that are connected to each other.",
    },
    {
        word: "IP address",
        sana: "IP-osoite",
        definition:
            "A number assigned to any item that is connected to the Internet.",
    },
    {
        word: "iteration",
        sana: "iterointi",
        definition:
            "A repetitive action or command typically created with programming loops.",
    },
    {
        word: "loop",
        sana: "silmukka",
        definition: "The action of doing something over and over again.",
    },
    {
        word: "online",
        sana: "verkossa",
        definition: "Connected to the Internet.",
    },
    {
        word: "output",
        sana: "tuloste",
        definition: "A way to get information out of a computer.",
    },
    {
        word: "packets",
        sana: "paketti",
        definition:
            "Small chunks of information that have been carefully formed from larger chunks of information.",
    },
    {
        word: "pattern matching",
        sana: "mallinmukaisuus",
        definition: "Finding similarities between things.",
    },
    {
        word: "parameter",
        sana: "parametri",
        definition:
            "An extra piece of information passed to a function to customize it for a specific need.",
    },
    {
        word: "persistence",
        sana: "jatkuva",
        definition: "Trying again and again, even when something is very hard.",
    },
    {
        word: "pixel",
        sana: "pikseli",
        definition:
            "Short for 'picture element', the fundamental unit of a digital image, typically a tiny square or dot that contains a single point of color of a larger image.",
    },
    {
        word: "program",
        sana: "ohjelma",
        definition:
            "An algorithm that has been coded into something that can be run by a machine.",
    },
    {
        word: "programming",
        sana: "ohjelmointi",
        definition: "The art of creating a program.",
    },
    {
        word: "programming language",
        sana: "ohjelmointikieli",
        definition:
            "A language that is used to write programs that can be run by a computer.",
    },
    {
        word: "frusterated",
        sana: "suuttunut",
        definition:
            "Feeling annoyed or angry because something is not the way you want it.",
    },
    {
        word: "repeat",
        sana: "toista",
        definition: "To do something again.",
    },
    {
        word: "function",
        sana: "funktio",
        definition:
            "A piece of code that you can easily call over and over again.",
    },
    {
        word: "function definition",
        sana: "funktion määrittely",
        definition:
            "The code inside a function that instructs the program on what to do when the function is called",
    },
    {
        word: "run program",
        sana: "suorita ohjelma",
        definition:
            "Cause the computer to execute the commands you've written in your program.",
    },
    {
        word: "search engine",
        sana: "hakukone",
        definition:
            "A program that searches for and identifies items in a database that correspond to keywords or characters specified by the user, used especially for finding particular sites on the World Wide Web.",
    },
    {
        word: "servers",
        sana: "palvelimet",
        definition: "Computers that exist only to provide things to others.",
    },
    {
        word: "toolbox",
        sana: "työkalupakki",
        definition:
            "The tall grey bar in the middle section of Code.org's online learning system that contains all of the commands you can use to write your program.",
    },
    {
        word: "trustworthy",
        sana: "luotettava",
        definition: "Able to be relied on as honest or truthful.",
    },
    {
        word: "try",
        sana: "yritä",
        definition: "Attempt to do something",
    },
    {
        word: "URL (universal resource locator)",
        sana: "URL",
        definition:
            "An easy-to-remember address for calling a web page (like www.code.org).",
    },
    {
        word: "username",
        sana: "käyttäjätunnus",
        definition:
            "A name you make up so that you can see or do things on a website, sometimes called a “screen name.”",
    },
    {
        word: "variable",
        sana: "muuttuja",
        definition: "A placeholder for a piece of information that can change.",
    },
    {
        word: "website",
        sana: "verkkosivu",
        definition:
            "A collection of interlinked web pages on the World Wide Web.",
    },
    {
        word: "while loop",
        sana: "toistosilmukka",
        definition:
            "A loop that continues to repeat while a condition is true.",
    },
    {
        word: "Wi-Fi",
        sana: "Wi-Fi",
        definition:
            "A wireless method of sending information using radio waves.",
    },
    {
        word: "workspace",
        sana: "työtila",
        definition:
            "The white area on the right side of Code.org's online learning system where you drag and drop commands to build your program.",
    },
];

export { words };
